import CreateActionCreator from 'helpers/createActionCreator';
import { 
  LOGIN,
  ZIPCODE, 
  SETTINGS, 
  FEEDBACK,
  FEEDBACKID,
  SOLARVALUES,
  PROJECTDATA, 
  FEEDBACKLOGIN,
} from 'configs/types';
import { REQUEST_URL } from 'configs';

const changeSettings = (payload) => ({
  type: SETTINGS,
  payload,
});

const setSolarValue = (payload) => ({
  type: SOLARVALUES,
  payload,
});

const sendAccountDetails = data =>
  CreateActionCreator.create({
    path: '/cal.php',
    data,
  });

const fetchZipCode = (code) =>
  CreateActionCreator.read({
    path: `https://designsolar.io:3010/utilities/zip?code=${code || 90001}`,
    type: ZIPCODE,
    headers: {
      client_app_id: 'nnAudh82Ncm4pOA',
    },
  });

const loginToAdmin = data => CreateActionCreator.create({
  path: `${REQUEST_URL}rest/api/v1/auth/login`,
  type: LOGIN,
  body: data,
  headers: {
    'Content-Type': 'application/json'
  },
});

const getProjectData = () => CreateActionCreator.read({
  path: `${REQUEST_URL}rest/api/v1/static-texts`,
  type: PROJECTDATA,
  needDataKey: true,
  headers: {
    'Content-Type': 'application/json'
  },
});

const checkIsTokenValid = token => CreateActionCreator.read({
  path: `${REQUEST_URL}rest/api/v1/auth/authenticate`,
  type: 'TOKEN',
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': token,
  },
});

const updateStaticText = data => CreateActionCreator.update({
  path: `${REQUEST_URL}rest/api/v1/static-texts`,
  type: 'STATICTEXT',
  body: data,
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': window.localStorage.getItem('token'),
  },
});

//Feedback tools actions

const getFeedback = _ => CreateActionCreator.read({
  path: `${REQUEST_URL}feedback-rest/feedback`,
  type: FEEDBACK,
  needToken: true,
  headers: {
    'Content-Type': 'application/json'
  },
});

const createFeedback = data => CreateActionCreator.create({
  path: `${REQUEST_URL}feedback-rest/feedback`,
  type: FEEDBACKID,
  body: data,
  needToken: true,
  headers: {
    'Content-Type': 'application/json'
  },
});

const loginToFeedback = data => CreateActionCreator.create({
  path: `${REQUEST_URL}feedback-rest/auth/login`,
  type: FEEDBACKLOGIN,
  body: data,
  headers: {
    'Content-Type': 'application/json'
  },
});

export { 
  getFeedback,
  createFeedback,
  loginToFeedback,
  loginToAdmin,
  fetchZipCode, 
  setSolarValue, 
  changeSettings,
  getProjectData, 
  updateStaticText,
  checkIsTokenValid,
  sendAccountDetails, 
};
