import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField, Button, Image, Modal } from 'components/index';
import { formatNumber, isEmail } from 'utils';
import newLogo from 'assets/new-design-logo.svg';
import NewsLetter from 'assets/newsletter.svg';
import Phone from 'assets/phone.svg';
import House from 'assets/house.svg';
import Finance from 'assets/finance.svg';
import './style.scss';
import { setSolarValue } from 'actions';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const mapStateToProps = ({ zipcode, solarValues, projectData }) => ({
  zipcode,
  solarValues,
  projectData,
});

export const RightBlock = ({ setSolarValue, children, needRedirect, userName }) => {
  const storageSolarValues = JSON.parse(localStorage.getItem('solarValues')) || {};
  const { email, homeAddress, phone, averageElectricBill } = storageSolarValues;
  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [editEmail, setEditEmail] = useState(email);
  const [editPhone, setEditPhone] = useState(phone || '');
  const [editHomeAddress, setHomeAddress] = useState(homeAddress);
  const [editAverageEletricBill, setEditAverageElectricBill] = useState(averageElectricBill);

  function onCloseModal() {
    setModalOpen(false);
    setEditInfo(false);
  }

  const words = userName && userName.split(' ');
  let initials = '';
  if (userName) {
    for (let i = 0; i < words.length; i++) {
      initials += words[i][0]?.toUpperCase();
      if (i === 1) break;
    }
  }

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'email') setEditEmail(value);
    if (name === 'homeAddress') setHomeAddress(value);
    if (name === 'averageElectricBill') setEditAverageElectricBill(value);
    if (name === 'phone') setEditPhone(value);
  };

  const handleSave = () => {
    setSolarValue({
      email: editEmail,
      homeAddress: editHomeAddress,
      averageElectricBill: editAverageEletricBill,
      phone: editPhone,
    });
    setEditInfo(false);
  };

  return (
    <div className="RightBlock flexible jCenter">
      <div className="content-block">
        <div className="flexible aStart jCenter w-100">
          <Link
            className={userName && 'w-100'}
            disabled={!needRedirect}
            to={needRedirect ? '/' : ''}
          >
            {userName ? (
              <div className="user-modal flexible jBetween d-flex">
                <Image src={newLogo} width={150} height={70} />
              </div>
            ) : (
              <Image src={newLogo} width={152} height={67} />
            )}
          </Link>
          {!!userName && (
            <Button className="user-icon" onClick={() => setModalOpen(true)}>
              {initials}
            </Button>
          )}
        </div>
        {children}
      </div>
      {modalOpen && (
        <Modal closeModal={onCloseModal}>
          <div className="user-modal flexible">
            <Button className="user-modal-icon" onClick={() => setModalOpen(true)}>
              {initials}
            </Button>
            <p className="user-name flexible align-center ml-2">{userName}</p>
          </div>
          <div className="user-modal mt-2">
            <div className="mt-1 flexible">
              <div className="user-img ">
                <img src={NewsLetter} alt="newsletter" className="mr-2" />
              </div>
              {editInfo ? (
                <input
                  className="user-edit-input"
                  value={editEmail}
                  name="email"
                  onChange={handleInputChange}
                  id="email"
                  isValid={isEmail(editEmail) && !!editEmail}
                  {...(!isEmail(editEmail) && { errorText: 'Please use valid email' })}
                />
              ) : (
                <span className="user-text">{editEmail}</span>
              )}
            </div>
            <div className="mt-1 flexible">
              <div className="user-img ">
                <img src={Phone} alt="Phone" className="mr-2" />
              </div>
              {editInfo ? (
                <input
                  className="user-edit-input"
                  value={editPhone}
                  name="phone"
                  onChange={handleInputChange}
                  id="phone"
                />
              ) : (
                <span className="user-text">{editPhone}</span>
              )}
            </div>
            <div className="mt-1 flexible">
              <div className="user-img ">
                <img src={House} alt="House" className="mr-2" />
              </div>
              {editInfo ? (
                <input
                  className="user-edit-input"
                  value={editHomeAddress}
                  name="homeAddress"
                  onChange={handleInputChange}
                  id="homeAddress"
                />
              ) : (
                <span className="user-text">{editHomeAddress}</span>
              )}
            </div>
            <div className="mt-1 flexible">
              <div className="user-img ">
                <img src={Finance} alt="Finance" className="mr-2" />
              </div>
              {editInfo ? (
                <input
                  className="user-edit-input"
                  value={editAverageEletricBill}
                  name="averageElectricBill"
                  onChange={handleInputChange}
                  id="averageElectricBill"
                />
              ) : (
                <span className="user-text">
                  {`$${formatNumber(editAverageEletricBill)}`} average eletric bill
                </span>
              )}
            </div>
            <div className="flexible jCenter">
              <Button
                className="edit-button"
                onClick={(e) => {
                  !editInfo ? setEditInfo(true) : handleSave();
                }}
              >
                {editInfo ? 'Save' : 'Edit My Info'}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

RightBlock.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps, {
  setSolarValue,
})(RightBlock);
