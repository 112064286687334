import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SettingsComponent } from 'components';

import { getProjectData } from 'actions';
import { useMount } from 'utils';

import RouteBuilder from './routes';

import 'sass/main.scss';
import 'sass/media.scss';
import 'sass/animate.scss';

const App = ({ getProjectData }) => {
  const { pathname } = useLocation();

  useMount(() => {
    getProjectData();
  });

  return (
    <main>
      {!pathname.includes('admin') && <SettingsComponent/>}
      <RouteBuilder />
    </main>
  );
};

export default connect(null, { getProjectData })(App);
