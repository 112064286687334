import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import { Image, Icon, li } from 'components';

import { changeSettings } from 'actions';
import { initialSettings } from 'configs';

import { wheelchairIcon } from 'assets/icons';

import './style.scss';

const mapStateToProps = ({ settings }) => ({ settings });

const SettingsComponent = ({ settings, changeSettings }) => {
  const [isOpen, setIsOpen] = useState();
  const {
    grayscale,
    highContrast,
    readableFont,
    increaseText,
    linksUnderline,
    lightBackground,
    negativeContrast,
  } = settings;

  const handleChange = value => {
    changeSettings({ ...settings, ...value });
  }

  return (
    createPortal(
      <div className={classnames('SettingsComponent', { 'active': isOpen })}>
          <div className="settings-content flexible">
            <div 
              className="image-block flexible aCenter jCenter"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Image src={wheelchairIcon} width={30} height={30}/>
            </div>
            <div className="settings-body">
              <li className="title">Accessibility Tools</li>
              <ul>
                <li 
                  type="li" 
                  onClick={()=> changeSettings({
                    increaseText: !increaseText 
                      ? 130 
                      : increaseText >= 200 
                        ? 200
                        : increaseText + 10
                  })}
                  className={classnames('flexible aCenter', { 'active' : ''})}
                >
                  <Icon name="icon-zoom-in" /> Increase Text
                </li>
                <li 
                  type="li" 
                  className="flexible aCenter"
                  onClick={()=> changeSettings({
                    increaseText: !increaseText || increaseText <= 130 
                      ? null 
                      : increaseText - 10
                  })}
                >
                  <Icon name="icon-zoom-out" /> Decrease Text
                </li>
                <li type="li" className={classnames('flexible aCenter', { 'active' : grayscale })} onClick={() => handleChange({ grayscale: !grayscale })}>
                  <Icon name="icon-grayscale-1" /> Grayscale
                </li>
                <li type="li" className={classnames('flexible aCenter', { 'active' : highContrast })} onClick={() => handleChange({ highContrast: !highContrast })}>
                  <Icon name="icon-contrast" /> High Contrast
                </li>
                <li type="li" className={classnames('flexible aCenter', { 'active' : negativeContrast })} onClick={() => handleChange({ negativeContrast: !negativeContrast })}>
                  <Icon name="icon-negative" /> Negative Contrast
                </li>
                <li className={classnames('flexible aCenter', { 'active' : lightBackground })} onClick={() => handleChange({ lightBackground: !lightBackground })}>
                  <Icon name="icon-light" /> Light Background
                </li>
                <li type="li" className={classnames('flexible aCenter', { 'active' : linksUnderline })} onClick={() => handleChange({ linksUnderline: !linksUnderline })}>
                  <Icon name="icon-link" /> Links Underline
                </li>
                <li type="li" className={classnames('flexible aCenter', { 'active' : readableFont })} onClick={() => handleChange({ readableFont: !readableFont })}>
                  <Icon name="icon-readable" /> Readable Font
                </li>
                <li type="li" className="flexible aCenter" onClick={()=> changeSettings(initialSettings)}>
                  <Icon name="icon-reset" /> Reset
                </li>
              </ul>
            </div>
          </div>
      </div>,
      document.querySelector('#root-settings'),
    )
  )
};

export default connect(mapStateToProps, {
  changeSettings,
})(SettingsComponent);