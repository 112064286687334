const isEmail = emailString => {
  // check for @ sign
  var atSymbol = emailString?.indexOf("@");
  if(atSymbol < 1) return false;
  
  var dot = emailString?.indexOf(".");
  if(dot <= atSymbol + 2) return false;
  
  // check that the dot is not at the end
  if (dot === emailString?.length - 1) return false;
  
  return true;
}

export default isEmail;