import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import { Paper, Image, Button, BlurredCard } from 'components';

import './style.scss';

const InfoCard = ({
  id,
  url,
  info,
  title,
  imageSrc,
  direction,
  buttonText,
  onButtonClick,
  highlightTitle,
  highlightFirst,
}) => {
  const { push } = useHistory();
  return (
    <div
      className={classnames('InfoCard flexible', {
        vertical: direction === 'bottom',
        leftDirection: direction === 'left',
        rightDirection: direction === 'right',
      })}
    >
      <div className="Image-block mobile-main-photo">
        <Image src={imageSrc} />
      </div>
      <div className="Info-block flexible aCenter grow">
        {direction === 'bottom' ? (
          <BlurredCard
            text1={highlightFirst ? title : highlightTitle}
            text2={!highlightFirst ? title : highlightTitle}
            description={info}
            buttonText={buttonText}
            buttonId={id}
            onButtonClick={(event) => {
              push(url);
              onButtonClick(event);
            }}
          />
        ) : (
          <div className="info-block-container jCenter flexible vertical">
            <div className="Info-block-title">
              {highlightFirst ? (
                <Paper type="h4">
                  <Paper type="span">{highlightTitle}</Paper> {title}
                </Paper>
              ) : (
                <Paper
                  type="h4"
                  className={(id === 'battery' || id === 'design-durability') && 'color-black'}
                >
                  {title} <Paper type="span">{highlightTitle}</Paper>
                </Paper>
              )}
            </div>
            <Paper className="Info-block-desc">{info}</Paper>
            <Link to={url}>
              <Button id={id} onClick={onButtonClick}>
                {buttonText}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  info: PropTypes.string,
  title: PropTypes.string,
  imageSrc: PropTypes.any,
  direction: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  highlightFirst: PropTypes.bool,
  highlightTitle: PropTypes.string,
};

InfoCard.defaultProps = {
  direction: 'bottom',
  highlightFirst: true,
  onButtonClick: () => {},
};

export default InfoCard;
