import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const Icon = ({ name = '', className = '', color, ...restProps }) => {
  return (
    <i style={{ color: color }} className={classnames('Icon', name, className)} {...restProps} />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
