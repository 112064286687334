import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const Image = ({ src, alt, width, height, className, maxHeight, maxWidth, ...restProps }) => (
  <img
    style={{ width, height, maxHeight, maxWidth }}
    className={classnames('Image', className)}
    src={src}
    alt={alt}
    {...restProps}
  />
);

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default Image;
