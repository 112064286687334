import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from 'components';

import './style.scss';

const Title = ({ name, subtitle }) => (
  <h2 className="Title flexible vertical">
    <Paper type="span" className="name">{name}</Paper>
    {!!subtitle && <Paper type="span" className="subtitle">{subtitle}</Paper>}
  </h2>
);

Title.propTypes = {
  name: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Title;