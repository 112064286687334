import createReducer from 'helpers/createReducer';
import { combineReducers } from 'redux';
import {
  LOGIN,
  ZIPCODE,
  SETTINGS,
  FEEDBACK,
  SOLARVALUES,
  PROJECTDATA,
  FEEDBACKLOGIN,
} from 'configs/types';
import data from 'configs/data';
import { initialSettings, initialSolarValues } from 'configs';

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const storageSolarValues = localStorage.getItem('solarValues');

const solarValues = (
  state = storageSolarValues ? JSON.parse(storageSolarValues) : initialSolarValues,
  action,
) => {
  switch (action.type) {
    case SOLARVALUES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const solarValues4All = (
  state = JSON.parse(window.localStorage.getItem('user_solar_values') || '{}'),
  action,
) => {
  switch (action.type) {
    case 'UPDATE_SOLAR_ALL':
      const newState = { ...state, [action.payload.email]: action.payload };
      window.localStorage.setItem('user_solar_values', JSON.stringify(newState));
      return newState;
    default:
      return state;
  }
};

const projectData = createReducer(PROJECTDATA, null, data);
const zipcode = createReducer(ZIPCODE);
const login = createReducer(LOGIN);
const feedback = createReducer(FEEDBACK);
const feedbackLogin = createReducer(FEEDBACKLOGIN);

const rootReducer = combineReducers({
  login,
  zipcode,
  settings,
  feedback,
  solarValues,
  projectData,
  feedbackLogin,
  solarValues4All,
});

export default rootReducer;
