import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const Button = ({
    children,
    disabled,
    className,
    withLine,
    ...restProps
}) => {
    return (
        <button
            className={classnames('Button', {
              'disabled' : disabled,
              'withLine' : withLine,
              [className]: className,
            })}
            disabled={disabled}
            {...restProps}>
            {children}
        </button>
    );
}

Button.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
};

export default Button;
