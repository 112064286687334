import React from 'react';

import { Button } from 'components';

import './style.scss';

const BlurredCard = ({ 
  text1, 
  text2,
  buttonId, 
  buttonText,
  description,
  onButtonClick,
}) => (
  <div className="BlurredCard">
    <h3>{text1} <span>{text2}</span></h3>
    <p>{description}</p>
    <Button onClick={onButtonClick} id={buttonId} >{buttonText}</Button>
  </div>
);

export default BlurredCard;