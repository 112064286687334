import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Image } from 'components';

import './style.scss';

const InfoIcon = ({ icon, info, subText }) => (
  <div className="InfoIcon flexible card vertical aCenter">
    <Image src={icon} width={120} height={120} alt={info || ''} />
    <Paper className="InfoIcon_value">{info}</Paper>
    <Paper type="p" className="sub-text mt-1">
      {subText}
    </Paper>
  </div>
);

InfoIcon.propTypes = {
  icon: PropTypes.any,
  info: PropTypes.string,
};

export default InfoIcon;
