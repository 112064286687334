import React from 'react';
import classnames from 'classnames';

import './style.scss';

const BusyLoader = ({ isBusy, children, className }) => (
  <div className={classnames('BusyLoader', className)}>
    {isBusy &&
      <div className="loading-content flexible aCenter jCenter">
        <div className="loader" />
      </div>
    }
    {children}
  </div>
);

export default BusyLoader;
