import React, { memo, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Preloader } from 'components';
// import LoadingImg from 'assets/Bitmap.png'
import routes from './configs';

const mapStateToProps = ({ feedbackLogin }) => ({ feedbackLogin });

const RouteBuilder = ({ feedbackLogin }) => {

  useEffect(() => {
    if(feedbackLogin?.token?.accessToken) {
      window.localStorage.setItem('feedbackToken', feedbackLogin.token.accessToken);
      window.localStorage.setItem('feedbackUserData', feedbackLogin?.user);
    }
  },[feedbackLogin]);

  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        {routes.map(({ component: Component, path, exact }) => (
          <Route key={path} exact={!!exact} path={`${path}`} component={Component} />
        ))}
        <Redirect from="/" to="/" />
      </Switch>
    </Suspense>
  );
};

export default connect(mapStateToProps, null)(memo(RouteBuilder));

export { routes };
