import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const TextField = ({
  type,
  value,
  isValid,
  readOnly,
  onChange,
  className,
  rightText,
  errorText,
  placeholder,
  startAdornment,
  ...restProps
}) => {
  return (
    <div className="TextField">
      {readOnly
        ? <div className="keyValue">
            <div className="keyValue_key">{placeholder}</div>
            <div className="keyValue_value">{value}</div>
          </div>
        : <>
            {!!value && <div className={classnames('TextField_label', { 'hasAdornment': !!startAdornment })}>{placeholder}</div>}
            {startAdornment && <div className="start-adornment flexible aCenter jCenter">{startAdornment}</div>}
            <input className = {classnames('TextField_input', {
              [className]: className,
              'rightText': !!rightText,
              'hasStartAdornment': !!startAdornment,
            })}
              placeholder={placeholder}
              type = {type || 'text'}
              onChange = {onChange}
              value = {value || ''}
              {...restProps}
            />
            {rightText && <div className="TextField_rightText">{rightText}</div>}
            {!isValid && !!errorText && <div className="TextField_errorText">* {errorText}</div>}
          </>
      }
    </div>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  className: PropTypes.string,
  rightText: PropTypes.string,
  errorText: PropTypes.string,
  startAdornment: PropTypes.any,
  value: PropTypes.any,
};

TextField.defaultProps = {
  isValid: true,
};

export default TextField;