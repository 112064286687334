import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ProgressBar = ({ title, data }) => {

  const maxCount = useMemo(() => (
    data.reduce((prev, item) => prev + item)
  ), [data]);

  const getPrecent = useCallback(
    item => (item * 100 / maxCount).toFixed(0), 
  [maxCount]);

  return (
    <div className="ProgressBar flexible jEnd aCenter">
      <div className="title">{title}</div>
      <div className="bar-content flexible">
        {data.map((item, key) => (
          <div 
            className="bar" 
            key={key} 
            style={{ 
              width: `${getPrecent(item)}%`,
              backgroundColor: key === 0 ? '#FFC700': '#CBDAE7' 
            }}
          >
            ${item}
          </div>
        ))}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  title: PropTypes.string,
};

ProgressBar.defaultProps = {
  data: [],
};

export default ProgressBar;
