import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { noop } from 'utils';

import './style.scss';

const customStyles = {
  control: styles => ({ 
    ...styles,  
    height: 70,
    borderRadius: 16,
    borderColor: '#545475',
    outline: 'none',
    paddingLeft: 18,
  }),
  placeholder: styles => ({ 
    ...styles,  
    color: 'rgba(0, 0, 50, 0.67)',
    fontSize: 16,
    fontWeight: 500,
  }),
}

const SelectField = ({ options, placeholder, onChange, value, id, ...restProps }) => {
  return (
    <div className="SelectField" id={id}>
      <Select 
        options={options} 
        placeholder={placeholder}
        onChange={onChange}
        styles={customStyles}
        value={value}
        {...restProps}
      />
    </div>
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

SelectField.defaultProps = {
  options: [],
  onChange: noop,
};


export default SelectField;