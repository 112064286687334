import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const mapStateToProps = ({ settings }) => ({ settings });

const Paper = forwardRef(({
  type: Tag,
  style,
  onClick,
  children,
  className,
  settings,
}, ref) => {
  const {
    readableFont,
    increaseText,
    linksUnderline,
    lightBackground,
  } = settings;

  return (
    <Tag
      className={classnames('Paper', className)}
      onClick={onClick}
      style={{
        ...style,
        ...(readableFont && { fontFamily: 'Verdana,Arial,Helvetica,sans-serif' }),
        ...(lightBackground && { color: '#000000', backgroundColor: '#ffffff' }),
        ...(linksUnderline && (Tag === "a") && { textDecoration: 'underline' }),
        ...(increaseText && { fontSize: `${increaseText}%` }),
      }}
      ref={ref}
    >
      {children}
    </Tag>
  );
});

Paper.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  height: PropTypes.number,
  className: PropTypes.string,
};

Paper.defaultProps = {
  type: 'div',
};

export default connect(mapStateToProps)(Paper);
