import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Paper } from 'components';

import './style.scss';


const KeyValue = ({ name, value, withBorder, withBoldName, isVertical }) => (
  <div className={classnames('KeyValue flexible', { 
    'withBorder':  withBorder,
    'withBoldName': withBoldName,
    'vertical': isVertical,
    'aCenter': !isVertical,
    'jBetween': !isVertical,
    })}>
    <Paper className="KeyValue_name">
      {name}
    </Paper>
    <Paper className="KeyValue_value">
      {value}
    </Paper>
  </div>
);

KeyValue.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  withBorder: PropTypes.bool,
  isVertical: PropTypes.bool,
  withBoldName: PropTypes.bool,
};

export default KeyValue;