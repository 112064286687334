import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Paper } from 'components';

import './style.scss';

export const Tabs = ({
  list = [],
  type = '',
  align = 'left',
  getActiveTab = () => {},
  selected,
}) => {
  const [activeTab, setActiveTab] = useState(selected);

  useEffect(() => {
    setActiveTab(selected);
  }, [selected]);

  function handleChange(index, name) {
    return function () {
      getActiveTab(name);
    };
  }

  return (
    <div className="tabs-wrap">
      <ul className={classnames('tabs-list', type, align)}>
        {list.map((item, index) => {
          return (
            <Paper type="li" key={index}>
              <button
                onClick={handleChange(index, item.key)}
                className={item.key === activeTab ? 'active' : ''}
              >
                <Paper type="span">
                  {item.name}
                </Paper>
              </button>
            </Paper>
          );
        })}
      </ul>
      <div className="tabs-body">
        {list.map((item, index) => {
          return (
            <Fragment key={index}>
              {activeTab === item.key && <div className="tab-content">{item.component}</div>}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  list: PropTypes.array,
  type: PropTypes.string,
  align: PropTypes.string,
  getActiveTab: PropTypes.func,
};

export default Tabs;
