import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setSolarValue } from 'actions';
import { initialSolarValues } from 'configs';

import { Image } from 'components';

import mainPhoto from 'assets/main-page-photo.png';

import './style.scss';

const mapStateToProps = ({ solarValues }) => ({ solarValues });

const MainImageBlock = ({ solarValues, setSolarValue }) => {
  const { averageElectricBill } = solarValues;
  const { push } = useHistory();
  const { pathname } = useLocation();

  const acceptCoockies = window.localStorage.getItem('acceptCoockies');

  useEffect(() => {
    averageElectricBill <= 0 && !pathname?.includes('cookies-policies') && !acceptCoockies && push('/main')
  }, [averageElectricBill, push, acceptCoockies, pathname])

  useEffect(() => {
    const storageSolarValues = localStorage.getItem('solarValues');

    if (!storageSolarValues) {
      localStorage.setItem('solarValues', JSON.stringify(initialSolarValues));
    } else {
      localStorage.setItem('solarValues', JSON.stringify(solarValues));
    }
  }, [setSolarValue, solarValues]);

  return (
    <div className="MainImageBlock">
      <Image 
        src={mainPhoto} 
        alt="Solar Panels on the House Roof"
        title="Solar Panels on the House Roof"
      />
    </div>
  );
};

export default connect(mapStateToProps, {
  setSolarValue,
})(MainImageBlock);
