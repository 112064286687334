import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const TextField = forwardRef(({
  isValid,
  readOnly,
  className,
  rightText,
  errorText,
  isTextarea,
  startAdornment,
  ...restProps
}, ref) => {
  return (
    <div className="TextField">
      {!isTextarea
      ?  !readOnly 
        ? <>
            <input ref={ref} className = {classnames('TextField_input', {
                [className]: className,
                'rightText': !!rightText,
                'readOnly': readOnly,
                'hasStartAdornment': !!startAdornment,
              })}
                {...restProps}
            />
            {startAdornment && <div className="start-adornment flexible aCenter jCenter">{startAdornment}</div>}
          </>
        : <div className="input-readOnly">{restProps?.value}</div>
      : <textarea
          ref={ref}
          className={classnames('TextField_input isTextarea' , { [className]: className })}
          {...restProps}
        />
      }
      
      {rightText && <div className="TextField_rightText">{rightText}</div>}
      {!isValid && !!errorText && <div className="TextField_errorText">* {errorText}</div>}
    </div>
  );
});

TextField.propTypes = {
  isValid: PropTypes.bool,
  className: PropTypes.string,
  rightText: PropTypes.string,
  errorText: PropTypes.string,
  startAdornment: PropTypes.any,
};

TextField.defaultProps = {
  isValid: true,
  type: 'text',
  value: '',
  onChange: () => {},
};

export default TextField;