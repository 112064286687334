import durabilityImage from 'assets/durability.png';
import evergyBillImage from 'assets/energy-bills.png';
import teslaBatteryImage from 'assets/tesla-battery.png';
import enphaseBatteryImage from 'assets/enphase-battery.png';
import solarInstallationImage from 'assets/solar-installation.png';
const liveURL = 'https://a.designmysolar.com/';

export const BASE_URL = 'https://designsolar.io:3010/';
export const REQUEST_URL = liveURL;
export const MAIN_URL = `${REQUEST_URL}feedback/customer`;

export const initialSettings = {
  increaseText: null,
  descreaseText: null,
  grayscale: false,
  highContrast: false,
  negativeContrast: false,
  lightBackground: false,
  linksUnderline: false,
  readableFont: false,
};

export const initialSolarValues = {
  loanIndex: 0.0033,
  dealerFee: 0.645,
  isLoan: false,
  period: 25,
  percent: 2.99,
  month: 300,
  sizeWatt: 430,
  smartMainPannel: 1900,
  warrantyPrice: 0,
  installPrice: 0,
  systemSize: null,
  calSizeWatt: null,
  panelCount: null,
  powerPrice: null,
  powerCount: null,
  powerSize: null,
  powerBrand: null
};

export const mainPageData = [
  // {
  //   id: 'energy-bills',
  //   title: 'Save on',
  //   direction: 'bottom',
  //   buttonText: 'Start saving now',
  //   highlightTitle: 'Energy Bills',
  //   url: '/main',
  //   imageSrc: evergyBillImage,
  //   info:
  //     'Power your home with solar energy and become grid-independent. Reduce your electricity bills by being in complete control of where your energy comes from and avoiding the impact of utility rate increases. Go solar with the lowest cost solar panels from the best manufacturers in the industry. Want to know more about your potential savings? See our best estimate based on your average electricity bill.',
  // },
  {
    id: 'battery',
    title: 'Get the Best Solar Panels & Home Batteries',
    direction: 'left',
    buttonText: 'Design My Solar!',
    highlightTitle: 'at the Lowest Price',
    url: '/main',
    imageSrc: teslaBatteryImage,
    info:
      'With DesignMySolar.com, you have access to the best home energy products on the market at a fraction of the cost. Choose from a selection of high-efficiency panels in a sleek, modern, black-on-black design, and home batteries from trusted manufacturers like Tesla and Enphase. Use our solar design tool to get a customized recommendation and then adjust according to your preferences. Click below to get started!',
  },
  {
    id: 'enphase-storage',
    title: '',
    direction: 'bottom',
    buttonText: 'Design My Solar!',
    highlightTitle: 'Get the Best Home Batteries at the Lowest Price',
    url: '/main',
    imageSrc: enphaseBatteryImage,
    info:
      'Get the most value from your solar panels and protect yourself from utility outages with home battery backup. We offer home batteries from the most trusted names in the industry, including Tesla and Enphase. You won’t find a better price from anyone else - click below and choose “YES” when asked if you’re interested in home battery and we’ll recommend a system.',
  },
  {
    id: 'solar-panel',
    title: 'Get the Job Done Right - Guaranteed!',
    direction: 'bottom',
    highlightFirst: false,
    buttonText: 'Design My Solar!',
    highlightTitle: '',
    url: '/main',
    imageSrc: solarInstallationImage,
    info:
      'After you check out, we’ll review your system design and ensure you’re set up to get the best value for your investment. If we have any recommendations, we’ll get in touch and walk you through the details. Once everything looks good, we’ll take care of the rest, and keep you posted along the way. You can rest assured that your project is in good hands with our in-house management team and expert installers.',
  },
  {
    id: 'design-durability',
    title: 'Top-Tier Solar Panels for a Bargain Price',
    direction: 'right',
    buttonText: 'Design My Solar!',
    highlightTitle: '',
    url: '/main',
    imageSrc: durabilityImage,
    info:
      'Our solar panels pack a punch without breaking the bank. With industry-leading efficiency marks (even in low sunlight conditions) in a sleek, modern, black-on-black design, you can’t beat our solar panels for price or performance. Click below to find out how many you need to power your home!',
  },
];
