import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { noop } from 'utils';

import './style.scss';

const Slider = ({ defaultValue, onChange, min, max, step, value}) => {
  const [rangeValue, setRangeValue] = useState(defaultValue);

  useEffect(() => {
    setRangeValue(value);
  }, [value]);

  return (
    <div className="Slider">
      <input 
        className="range" 
        type="range" 
        min={min} 
        max={max} 
        step={step} 
        value={rangeValue} 
        onChange={({ target }) => {
          setRangeValue(target.value);
          onChange(target.value);
        }}
      />
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
};

Slider.defaultProps = {
  onChange: noop,
};

export default Slider;