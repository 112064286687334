import React from 'react';
import classnames from 'classnames';

import { Image } from 'components';

import { SuccessIcon } from 'assets/icons';

import './style.scss';

const Checkbox = ({ checked, onChange }) => (
  <div 
    className={classnames('Checkbox flexible aCenter jCenter', { 'checked': checked })} 
    onClick={() => onChange(!checked)}
  >
    {checked && <Image src={SuccessIcon} width={16} height={11}/>}
  </div>
);

export default Checkbox;