import React, { useState } from 'react';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import './style.scss';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const SolarDatePicker = ({ placeholder, onChange, calendarClassName }) => {
  const [startDate, setStartDate] = useState();

  return (
    <DatePicker 
      selected={startDate} 
      onChange={date => {
        setStartDate(date);
        onChange(date);
      }} 
      minDate={addDays(new Date(), 2)}
      placeholderText={placeholder}
      calendarClassName={calendarClassName}
      dayClassName={date => {
        const isWeekend = date.getDay() === 6 || date.getDay() === 0;
        return isWeekend ? 'react-datepicker__day--disabled' : ''
      }}
    />
  );
};

export default SolarDatePicker;