import React, { useMemo } from 'react';

import { useFormatter } from 'utils';

import './style.scss';

const Table = ({
  data = [],
  columns = [],
}) => {

  const formatter = useFormatter();

  const tableColumns = useMemo(() =>
    columns?.map(item => <th key={item.dataKey}>{item.name}</th>), 
  [columns]);

  const tableData = useMemo(() =>
    data?.map((item, key) =>
      <tr key={item?._id || key}>
        {columns.map(el =>
          <td key={el.dataKey}>{formatter(el.type, item[el.dataKey], item)}</td>
        )}
      </tr>
    )
  , [data, columns, formatter]);

  return (
    <div className="Table">
      <table>
        <thead>
          <tr>
            {tableColumns}
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
