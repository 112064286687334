import React from 'react';

import './style.scss';

export default function Preloader() {
  return (
    <div className="Preloader">
      <div className="loader">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
