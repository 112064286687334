import React, { useMemo } from 'react';

import { Image, Paper } from 'components';

import './style.scss';

const Stepper = ({ data }) => {

  const stepperBody = useMemo(() => {
    if (!data?.length) return null;

    return data.map(({ title, subtitle, src }, index) => (
      <div className="stepper-item flexible" key={index}>
        <div className="stepper-icon flexible aCenter jCenter">
          <Image src={src} />
        </div>
        <div>
          <Paper className="stepper-title">{title}</Paper>
          <Paper className="steppr-subtitle">{subtitle}</Paper>
        </div>
      </div>
    ));
  }, [data]);
  
  return (
    <div className="Stepper">
      {stepperBody}
    </div>
  )
};
export default Stepper;