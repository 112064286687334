import React from 'react';

import { Rating } from 'containers/Feedback/components';

const useFormatter = () => {
  return (type, value) => {
    switch (type) {
      case 'RATE':
        return !!value 
          ? <div className="flexible jCenter"><Rating size="small" starRate={value} /> </div>
          : 'n/a';
      case 'YES_NO':
        return value ? 'YES' : 'NO';
      default:
        return value;
    };
  };
};

export default useFormatter;
