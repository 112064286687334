
export default class Fetch {
    static async request(options) {
        const ACCESS_FEEDBACK_TOKEN = window.localStorage.getItem('feedbackToken');
        const { method, path, headers, body, data, needDataKey, needToken } = options;
        let requestConfig = {
            method,
            headers: {
                'Accept': 'application/json',
                ...headers,
            },
            redirect: 'follow',
        };

        if (body) {
            requestConfig.body = JSON.stringify(body);
        } else if(data) {
            requestConfig.body = data;
        }

        if(needToken) {
            requestConfig.headers['Authorization'] = `Bearer ${ACCESS_FEEDBACK_TOKEN}`;
            requestConfig.headers['x-language-code'] = 'EN';
          }

        const result = await fetch(new Request(`${path}`, requestConfig))
        .then(res => res.json())
        .then(res => res);

        if (result?.status === 401 || result?.statusCode === 401) {
            window.localStorage.clear();
            window.location.reload();
        };

        return needDataKey ? result?.data : result;
    }

    /* GET (retrieve) */
    static get = options => Fetch.request({ ...options, method: 'GET' });

    /* POST (create) */
    static post = options => Fetch.request({ ...options, method: 'POST' });

    /* POST (create) */
    static patch = options => Fetch.request({ ...options, method: 'PATCH' });

    /* DELETE (remove) */
    static delete = options => Fetch.request({ ...options, method: 'DELETE' });
}
