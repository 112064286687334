import { lazy } from 'react';

const routes = [
  {
    title: 'Home',
    path: '/',
    component: lazy(() => import('containers/Home/index')),
    exact: true,
  },
  {
    title: 'Main',
    path: '/main',
    component: lazy(() => import('containers/Main/index')),
    exact: true,
  },
  {
    title: 'Account Details',
    path: '/checkout',
    component: lazy(() => import('containers/AccountDetails/index')),
    exact: true,
  },
  {
    title: 'Purchase',
    path: '/purchase',
    component: lazy(() => import('containers/Purchase/index')),
    exact: true,
  },
  {
    title: 'Coockies Policies',
    path: '/cookies-policies',
    component: lazy(() => import('containers/CoockiesPolicies/index')),
    exact: true,
  },
  {
    title: 'Feedback',
    path: '/feedback',
    component: lazy(() => import('containers/Feedback/Home/index')),
    exact: true,
  },
  {
    title: 'Feedback View',
    path: '/feedback/view',
    component: lazy(() => import('containers/Feedback/FeedbackView/index')),
    exact: true,
  },
  {
    title: 'Feedback Link',
    path: '/feedback/link',
    component: lazy(() => import('containers/Feedback/FeedbackLink/index')),
    exact: true,
  },
  {
    title: 'Feedback Customer',
    path: '/feedback/customer/:subject?',
    component: lazy(() => import('containers/Feedback/FeedbackCustomer/index')),
    exact: true,
  },
  {
    title: 'DMS Admin',
    path: '/admin',
    component: lazy(() => import('containers/Admin/index')),
    exact: true,
  },
  {
    title: 'Return To Custom',
    path: '/return-to-custom',
    component: lazy(() => import('containers/ReturnToCustom/index')),
    exact: false,
  },
  {
    title: 'Electric Bill',
    path: '/electric-bill',
    component: lazy(() => import('containers/ElectricBill/index')),
    exact: false,
  },
  // {
  //   title: 'Battery',
  //   path: '/battery',
  //   component: lazy(() => import('containers/Battery/index')),
  //   exact: false,
  // },
  {
    title: 'Design Solar',
    path: '/design-solar',
    component: lazy(() => import('containers/CustomDesign/index')),
    exact: false,
  },
  {
    title: 'ROI Details',
    path: '/roi-details',
    component: lazy(() => import('containers/ROIDetails/index')),
    exact: false,
  },
  {
    title: 'Custom Solar Panel',
    path: '/custom-solar-panel',
    component: lazy(() => import('containers/CustomSolarPanel/index')),
    exact: false,
  },
  {
    title: 'Custom Tesla Battery',
    path: '/custom-tesla-powerwall',
    component: lazy(() => import('containers/CustomBattery/index')),
    exact: false,
  },
  {
    title: 'Custom Finance Product',
    path: '/custom-finance-product',
    component: lazy(() => import('containers/CustomFinanceProduct/index')),
    exact: false,
  },
  {
    title: 'Custom Solar Main Panel',
    path: '/custom-solar-main-panel',
    component: lazy(() => import('containers/CustomSolarMainPannel/index')),
    exact: false,
  },
  {
    title: 'NotFound',
    path: '*',
    component: lazy(() => import('containers/notFound/index')),
    exact: false,
  },
];

export default routes;
