import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { createPortal } from 'react-dom';

import { Icon } from 'components';

import { noop } from 'utils';

import './style.scss';

const Modal = ({ closeModal, className, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
      if (event.target.className.includes('Modal-container')) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return createPortal(
    <div className={classnames('Modal', className)} ref={modalRef}>
      <div className="Modal-container flexible jCenter aStart">
        <div className="Modal-body flexible animate__animated animate__fadeInDown ">
          <Icon name="icon-close" color="white" onClick={closeModal} />
          <div>{children}</div>
        </div>
      </div>
    </div>,
    document.querySelector('#root-portal'),
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  className: PropTypes.string,
  onTabChange: PropTypes.func,
};

Modal.defaultProps = {
  onTabChange: noop,
};

export default Modal;
