import React from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import { Button, Image } from 'components';

import { coockiesIcon } from 'assets/icons';

import './style.scss';

const CoockieModal = () => {
  return createPortal(
    <div className="CoockieModal">
      <div className="CoockieModal_body">
      </div>
      <div className="CoockieModal_content flexible">
        <div className="flexible grow CoockieModal_content_block">
          <div className="text-block flexible jCenter aCenter grow">
            <div className="flexible aCenter">
              <Image src={coockiesIcon} />
              <p>We use cookies to improve your experience on our site. By using our site your consent cookies.</p>
            </div>
            <Link to="/cookies-policies">
              LEARN MORE
            </Link>
          </div>
          <div className="button-block flexible jStart aCenter">
            <Button onClick={() => {
              window.localStorage.setItem('acceptCoockies', true);
              window.location.reload();
            }}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#root-portal'),
  );
};

export default CoockieModal;