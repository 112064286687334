import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'containers/Feedback/components';

import activeStar from 'assets/active-star.svg';
import InActiveStar from 'assets/inactive-star.svg';

import './style.scss';

const Rating = ({ starRate, size, readOnly, onChange }) => {

  const [rate, setRate] = useState(starRate);

  useEffect(() => {
    setRate(starRate);
  }, [starRate]);

  const starSize = useMemo(() => (
    size === 'medium' ? 24 : size === 'small' ? 16 : 54
  ), [size]);

  return (
    <div className="Rating flexible aCenter">
      {[...Array(5).keys()].map(item => (
        <Image 
          key={item}
          src={item < rate ? activeStar : InActiveStar} 
          width={starSize}
          height={starSize}
          className={size}
          {...(!readOnly && { onClick: () => {
            setRate(item + 1);
            onChange(item + 1);
          } })}
        />
      ))
      }
    </div>
  );
};

Rating.propTypes = {
  rate: PropTypes.number,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
};

Rating.defaultProps = {
  rate: null,
  size: 'medium',
  readOnly: true,
  onChange: () => {},
};

export default Rating;