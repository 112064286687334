import React from 'react';

import './style.scss';

const Card = ({ children }) => {
  return (
    <div className="Card">
      <div className="Card_body flexible vertical jBetween">
        {children}
      </div>
    </div>
  );
};

export default Card;