import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Paper } from 'components';

import { noop } from 'utils';

import './style.scss';

const RadioGroup = ({ title, onChange, actions, forSolar, defaultValue, value, id }) => {
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={classnames('RadioGroup', { 'forSolar': forSolar })} id={id}>
      <Paper className="RadioGroup_title">
        {title}
      </Paper>
      <div className="radio-block flexible">
        {actions.map(({ Name, value }) => (
          <div key={value} className={classnames('radio flexible aCenter', { 
            'active': selected === value,
          })} onClick={() => {
            setSelected(value);
            onChange(value);
          }}>
            <div className="flexible aCenter jCenter">
              {selected === value && !forSolar && <span/>}
            </div>
            <Paper type="span">{Name}</Paper>
        </div>
        ))}
      </div>
    </div>
  )
};

RadioGroup.propTypes = {
  title: PropTypes.string,
  forSolar: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string,
      value: PropTypes.any,
    })
  ),
};

RadioGroup.defaultProps = {
  onChange: noop,
  actions: [],
  forSolar: false,
};

export default RadioGroup;
