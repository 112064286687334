import React from 'react';
import PropTypes from 'prop-types';

import { Rating } from 'containers/Feedback/components';

import './style.scss';

const RecentReviewsCard = ({ title, name, rate }) => {

  return (
    <div className="RecentReviewsCard flexible vertical">
      <div className="flexible aCenter name">
        {name}
        <Rating size="medium" starRate={rate} />
      </div>
      <div className="flexible title">
        <p>{title}</p>

      </div>
    </div>
  );
};

RecentReviewsCard.propTypes = {
  rate: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
};

RecentReviewsCard.defaultProps = {
  rate: null,
  name: 'medium',
  title: true,
};

export default RecentReviewsCard;