import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Paper } from 'components';

import { noop } from 'utils';

import './style.scss';

const Switcher = ({ options, onSwitch, value }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(value);
  }, [value]);
  
  return (
    <div className="Switcher">
      {options.map(({ name, key }) => (
        <Paper
          className={classnames('Switcher_item flexible aCenter jCenter', { 'active': selected === key })} 
          key={key}
          onClick={() => {
            onSwitch(key);
            setSelected(key);
          }}
        >
          {name}
        </Paper>
      ))}
    </div>
  );
};

Switcher.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.number,
    })
  ),
  onSwitch: PropTypes.func,
};

Switcher.defaultProps = {
  options: [],
  onSwitch: noop,
};

export default Switcher;