import React from 'react';

import './style.scss';
import LoadingImg from 'assets/Bitmap.png'
const Loader = () => {
  return (
    <div className="Loader">
      <image src={LoadingImg} alt="LoadingImg"/>
    </div>
  );
};

export default Loader;